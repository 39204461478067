const LanguageReducer = (state, action) => {

    switch (action.type) {


        case " Language_loading":

            return {
                ...state,
                isLoading: true,

            };
        case "myLanguage":
            return {

                ...state,
                isLoading: false,
                Language: action.payload,

            }

        case "languageError":
            return {

                ...state,
                isLoading: false,
                isError: true,

            }
    }


}


export default LanguageReducer