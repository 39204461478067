const LocationReducer = (state, action) => {

    switch (action.type) {


        case " Location_loading":

            return {
                ...state,
                isLoading: true,

            };
        case "myLocation":
            return {

                ...state,
                isLoading: false,
                location: action.payload,

            }

        case "locationError":
            return {

                ...state,
                isLoading: false,
                isError: true,

            }
    }


}


export default LocationReducer