import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from './context/LanguageContext'
import { useLocationContext } from './context/LocationContext'
import './csscomponents/serviceheader.css';
import Noproduct from './Noproduct';
import PageNavigation from './PageNavigation';
import singleProductData from './SingleService'
const Serviceheader = () => {


    const [formData,setFormData] = useState({
  
      location: '',
      langauge: '',
  
    })
    const[res,setres]=useState([])
    const navigate=useNavigate()
  const handleChange=(event)=>{
    const name=event.target.name;
    const value=event.target.value;
    setFormData({...formData,[name]:value})
    console.log(formData)
  }
  const handleSubmit=async(event)=>{
  event.preventDefault();
  try{
  const response=await axios.get(`http://3.109.4.40:4000/api/product?location=${formData.location}&language=${formData.langauge}`)
  navigate('/pujaservices',{ state:  response.data })
  console.log(response)
  
  }catch(error){
  
  
    console.error(error)
  }
  
  }

 
    return (

        <section className='serviceheaders'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-sm-6'>
                        {/* {singleProductData ? <PageNavigation name={singleProductData.title} /> : ''} */}
                        {/* {singleProductData ? <SingleService name={singleProductData} /> : ''} */}
                    </div>
                    <form className='d-flex serviceHeaderFormStyle' onSubmit={handleSubmit}>
                        <div className='col-lg-4  serviceHeaderColStyle' >
                            <div className="form-group rounded">
                                <select className="form-control selectStyle" id="exampleFormControlSelect1" name="location"
                                    onChange={handleChange}
                                    value={formData.location}>
                                    <option value="" selected disabled className='optionStyle'>----Location----</option>
                                    <option className='optionStyle'>Bengaluru</option>
                                    <option className='optionStyle'>Coimbatore</option>
                                    <option className='optionStyle'>Chennai</option>
                                    <option className='optionStyle'>Hyderabad</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4  serviceHeaderColStyle'>
                            <div className="form-group rounded">
                                <select className="form-control selectStyle" id="exampleFormControlSelect1" name="langauge"
                                    onChange={handleChange}
                                    value={formData.langauge}>
                                    <option value="" selected disabled className='optionStyle'>----Language----</option>
                                    <option className='optionStyle'>Tamil</option>
                                    <option className='optionStyle'>Kannada</option>
                                    <option className='optionStyle'>Hindi</option>
                                    <option className='optionStyle'>English</option>
                                    <option className='optionStyle'>Malayalam</option>
                                    <option className='optionStyle'>Telagu</option>

                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <button className='rounded btn btn-warning finder-button' >Find Best Service</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Serviceheader