// import { isEmptyArray } from "formik";
// import React from "react";
// import { NavLink } from "react-router-dom";
// import Swal from "sweetalert2";


const cartReducer = (state, action) => {
    if (action.type === "ADD_TO_CART") {
        let { update, contactInfo,updatedPrice,packages1,packages2 } = action.payload;
        let cartProduct;
        cartProduct = {

            id: update._id,
            title: update.title,
            imgurl: update.image,
            updatedPrice: updatedPrice,
            contacts:contactInfo,
            count: update.count,
            packages1:packages1,
            packages2:packages2
        }
        console.log(cartProduct)
        let existingProductIndex = state.cart.findIndex(p => p.id === cartProduct.id)
        if (existingProductIndex !== -1) {
            alert("Product already in cart!")
            let newCart = [...state.cart];
            newCart[existingProductIndex] = cartProduct;
            return {
                ...state,
                cart: newCart
            }
        } else {
            return {

                ...state,
                cart: [...state.cart, cartProduct]

            }

        }

    }

    if (action.type === "REMOVE_ITEM") {
        let updatedCart = state.cart.filter((curItem) => curItem.id !== action.payload)
        return {

            ...state,
            cart: [...updatedCart],
        }

    }


    if (action.type === "CART_TOTAL_ITEM") {
        let updateItemVal = state.cart.reduce((initialVal, curElem) => {
            let { count } = curElem
            initialVal = initialVal + count;
            return initialVal

        }, 0)
        return {

            ...state,
            total_item: updateItemVal

        }

    }





    // if(action.type==="CLEAR_CART"){

    //     return{

    //         ...state,
    //         cart:[]
    //     }
    // }
    return state;


}

export default cartReducer