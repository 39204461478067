import React from 'react'
import { NavLink } from 'react-router-dom';
import FormatPrice from './Helpers/FormatPrice';
import './csscomponents/product.css'
const Product = ({ _id, image, title, Description, price, featured, stock }) => {
  // console.log(curElem)
  // const { } = curElem;
  return (
    <div className='col-lg-4  col-sm-12 pColStyle'>
      <div className='card pCardStyle '>
        <NavLink to={`/singleproduct/${_id}`}>
          <figure>
            <img src={image} alt={title} className='pImageStyle' />
          </figure>
        </NavLink>
        <NavLink to={`/singleproduct/${_id}`} className='crddata'>
          <div className='classStyle'>
            {title}
          </div>
        </NavLink>
      </div>
    </div>


  )
}

export default Product
