import { createContext, useReducer, useContext, useEffect } from "react";
import reducer from "../reducer/cartReducer"

const CartContext = createContext();

const getLocalCartData = () => {
    //   console.log('getData')
    let localCartData = localStorage.getItem("PujaCart")
    // console.log(localCartData)
    if (!localCartData) {
        return []
    } else {
        return JSON.parse((localCartData))
    }
}
const initialState = {

    // cart:[],
    cart: getLocalCartData(),
    total_item: "",
    total_amount: "",

}
const CartProvider = ({ children }) => {


    const [state, dispatch] = useReducer(reducer, initialState)
    const addtoCart = (update,contactInfo,updatedPrice,packages1,packages2) => {
        dispatch({ type: "ADD_TO_CART", payload: { update, contactInfo,updatedPrice,packages1,packages2} })
        console.log(update)
        console.log(contactInfo)
        console.log(updatedPrice)
    }

    const removeItem = (id) => {

        dispatch({ type: "REMOVE_ITEM", payload: id });
    };



    //to clear cart
    // const clearCart = () => {


    //     dispatch({ type: "CLEAR_CART" });

    // };
    //to add local storage
    // const [data, setData] = useState([]);
    useEffect(() => {
        // console.log(JSON.stringify(state.cart))
        dispatch({ type: "CART_TOTAL_ITEM" })
        localStorage.setItem("PujaCart", JSON.stringify(state.cart));
    }, [state.cart])





    return (
        <CartContext.Provider value={{ ...state, addtoCart, removeItem }}>{children}</CartContext.Provider>
    )


}
const useCartContext = () => {
    return useContext(CartContext)
}

export { CartProvider, useCartContext }