import ProductsData from "../ProductsData";
const ProductReducer = (state, action) => {
    // if (action.type === 'set_loading') {

    //     return {
    //         ...state,
    //         isLoading: true,

    //     }

    // };
    // if (action.type === 'dataError') {

    //     return {
    //         ...state,
    //         isLoading: true,
    //         isError: true,

    //     }
    // }
    switch (action.type) {

        case "set_loading":
            return {
                ...state,
                isLoading: true,

            };
                                     
        case "myData": {

            const featureData = action.payload.filter((currElement) => {
                return(currElement.featured === true) ;
                
            });
            return {

                ...state,
                isLoading: false,
                product: action.payload,
                featureProducts: featureData,
            }

        

        }
        case "dataError":
            return {
                ...state,
                isLoading: false,
                isError: true,

            };
        case "SET_SINGLE_LOADING":
            return {
                ...state,
                isSingleLoading: true,
            };


        case "SET_SINGLE_PRODUCT":
            
            return {
                ...state,
                isSingleLoading: false,
                singleProduct: action.payload,
            };
        case "SET_SINGLE_ERROR":
            return {
                ...state,
                isSingleLoading: false,
                isError: true,
            };
            case "set_filter_loading":
                return {
                    ...state,
                    isLoading: true,
    
                };
                case "myFilterData": {
                    const filterData = action.payload.filter((currElement) => {
                        return(currElement.location &currElement.langauge) ;
                        
                    });
                    return {
        
                        ...state,
                        isLoading: false,
                        product: action.payload,
                        filterData:filterData
                    
                    }
        
                }
                case "filter_data_Error":
                    return {
                        ...state,
                        isLoading: false,
                        isError: true,
        
                    };

        default:
            return {
                ...state,
            }
           


            
    }
}
export default ProductReducer