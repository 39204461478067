import React from 'react';
import Product from './Product';
import { useProductContext } from "./context/Productcontext"
import './csscomponents/featuredproduct.css'
import titletopimg from './image/logo02.png'
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Event from './Event';
const Featuredproduct = () => {
  const { isLoading, featureProducts } = useProductContext();
  console.log(featureProducts)
  // console.log(isLoading)
  if (isLoading) {
    return <div>.....loading</div>

  }
  // console.log(isLoading)
  // console.log(featureProducts)
  // console.log(currElem)
  return (
    <>
      <section className='product-fetures'>
        <div className='container fet-puja-section'>
          <img src={titletopimg} className='title-top-img' />
          <h2 className='h2Style-2' >Featured Pujas & Homas</h2>
          <div className='row fetured-pujas' >
            {featureProducts.map((currElem) => {
              return <Product key={currElem.id} {...currElem} />
            })}

          </div>
          {/* <Button className="serv-but" href="/pujaservices" >View All Services</Button> */}
          <NavLink to='/pujaservices'>
            <Button className="serv-but">View All Services</Button>
          </NavLink>

        </div>
      </section>
      
    </>


  )
}

export default Featuredproduct

