import React, { useState } from 'react';

function Userpujatracker() {
  const [products, setProducts] = useState([
    { name: 'Product 1', tracked: true },
    { name: 'Product 2', tracked: false },
    { name: 'Product 3', tracked: true },
  ]);

  function toggleProduct(index) {
    const updatedProducts = [...products];
    updatedProducts[index].tracked = !updatedProducts[index].tracked;
    setProducts(updatedProducts);
  }
    return (
        <div>
          <h1>Product Tracker</h1>
          <table>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Tracked</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.name}>
                  <td>{product.name}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={product.tracked}
                      onChange={() => toggleProduct(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }

export default Userpujatracker


  
