import axios from 'axios';
import { createContext, useContext, useEffect, useReducer } from 'react';
import ProductsData from '../ProductsData'
import reducer from '../reducer/Productreducer'

const AppContext = createContext();
// const API = ProductsData;
var API ="http://3.109.4.40:4000/api/product"
// console.log(API)
const initialState = {

    isLoading: false,
    isError: false,
    product: [],
    featureProducts: [],
    isSingleLoading: false,
    singleProduct: {},
}
// console.log(product)
const AppProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)
    const getProducts = async(url) => {
        dispatch({ type: 'set_loading' });
        try {
            const res = await axios.get(url);
            const product = await res.data ;
            console.log(product)
            dispatch({ type: "myData", payload: product })
            console.log(product)
        } catch (error) {
            dispatch({ type: 'dataError' });
        }

    }

    const getFilteredProduct=async(url)=>{
         dispatch({ type: 'set_filter_loading' });
         try{
            const res=await axios.get(url)
               const filteredProduct = await res.data ;
                console.log(filteredProduct)
                  dispatch({ type: "myFilterData", payload: filteredProduct })

         }catch(error){
             dispatch({ type: 'filter_data_Error' });
         }
    }

    const getSingleProduct =async (url) => {
        dispatch({ type: "SET_SINGLE_LOADING" });
        try {
            const res = await axios.get(url);
            const singleProduct = await  res.data;
            dispatch({ type: "SET_SINGLE_PRODUCT", payload: singleProduct  });
        } catch (error) {
            dispatch({ type: "SET_SINGLE_ERROR" });
        }
    };

    useEffect(() => {
        getProducts(API);
    }, [])
    return <AppContext.Provider value={{ ...state, getSingleProduct,getProducts,getFilteredProduct }}>
        {children}
    </AppContext.Provider>
}

const useProductContext = () => {
    return useContext(AppContext)

}

export { AppProvider, AppContext, useProductContext }