import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import AddToCart from './AddToCart';
import CartItem from './CartItem';
import Checkdate from './Checkdate';
import { useProductContext } from './context/Productcontext';
import './csscomponents/viewPrice.css'
import DateItem from './DateItem';
import Event from './Event';
import Serviceheader from './ServiceHeader'
const today = new Date();
const yesterday = new Date(today.getTime() - (24 * 60 * 60 * 1000));
const todayDate = today.toDateString();
const ViewPrice = (props) => {
    const [getPrice, setPrice] = useState()
    const [packages1, setPackage1] = useState()
    const [packages2, setPackage2] = useState()
    const [secondIncludes, setSecondIncludes] = useState()
    const [includess, setIncludes] = useState()
    const [priceOption, setPriceOption] = useState();
    const [isCheck, setIsCheck] = useState(false)
    const [updatedPrice, setUpdatedPrice] = useState()
    const [selectedDate, setSelectedDate] = useState(todayDate);
    // console.log("pricesss")
    const [contactInfo, setContactInfo] = useState({
        date: "",
        time: ""
    });
    const [date, setDate] = useState()
    const { getSingleProduct, isSingleLoading, singleProduct } =
        useProductContext();
    let location = useLocation();
    const { Form } = location.state || {}
var update=Form.singleProduct


    // console.log(singleProduct)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setContactInfo({ ...contactInfo, [name]: value });
        console.log(contactInfo)

    };

    const clickthefunction1 = (val) => {
        console.log(val)
        var mainprice = val.price1.price_1
        var packageName = val.price1.packagename_1
        console.log(packageName)
        var includesName = val.price1.inclusion_1
        setPrice(mainprice)
        setPackage1(packageName)
        setIncludes(includesName)
        setPackage2("")
    }
    const clickthefunction2 = (val) => {
        console.log(val)
        var mainprice = val.price2.price_2
        var packageName = val.price2.packagename_2
        console.log(packageName)
        var includesName = val.price2.inclusion_2
        setPrice(mainprice)
        setPackage2(packageName)
        setIncludes(includesName)
        setPackage1("")
    }
    const handleSubmit = (event) => {
        // prevents the submit button from refreshing the page
        event.preventDefault();
        if (new Date(selectedDate).toDateString() === yesterday.toDateString()) {
            alert("You cannot select yesterday's date");
        } else if
            (contactInfo.date) {
            setContactInfo({ date: "", time: "" })
        }
        // console.log(contactInfo);
        console.log(selectedDate);
    };

    const handlePriceChange = (event) => {
        setPriceOption(event.target.value);
        setIsCheck(!isCheck);
        if (event.target.value === 'Full') {
            setUpdatedPrice(getPrice);
        }
        else {
            setUpdatedPrice((getPrice/ 2));
        }
    };
    // console.log(Form.singleProductData.price.description)

    return (
        <>

            <Serviceheader />

            <section className='psBackGround100'>
                <div className='container package-start'>
                    <div className='row'>
                        <div className='col-lg-8 start-here'>
                            <div className='packages-loopsection'>
                                <h2 >
                                    <span className='cust-back backfrompackage'>
                                        Select Your Package
                                    </span>
                                </h2>
                                <div className=''>
                                    <div className='container start-packs'>
                                                <div className='row d-flex viewPriceStyle'  style={{ marginBottom: '50px' }} >
                                                    <div className='col-6 inclusion'>
                                                        <div className='puja-packages'>{Form.singleProduct?Form.singleProduct.price1.packagename_1:""}</div>
                                                        <p>Procedure Involved:</p>
                                                        <ul className='packageUL'>
                                                        
                                                                <i class="bi bi-check2-circle">
                                                                    <li className='packageLI' >{Form.singleProduct?Form.singleProduct.price1.descrip_1:""} </li>
                                                                </i>
                                                          

                                                        </ul>
                                                    </div>
                                                    <div className='col-3 inclusion '>
                                                        Inclusions:<br /><br />
                                                        <ul>
                                                                <li >{Form.singleProduct?Form.singleProduct.price1.inclusion_1:""} </li>
                                                        </ul>
                                                    </div>
                                                    <div className='respriceStyle col-3'>
                                                        <span className='packagePrice'> Price:-
                                                        {Form.singleProduct?Form.singleProduct.price1.price_1:""}<br /><br />
                                                        </span>
                                                        <Button onClick={() => clickthefunction1(update)} variant="success" className='singlebs'>Select Package</Button>
                                                    </div>
                                                </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='container start-packs'>
                                                <div className='row d-flex viewPriceStyle'  style={{ marginBottom: '50px' }} >
                                                    <div className='col-6 inclusion'>
                                                        <div className='puja-packages'>{Form.singleProduct?Form.singleProduct.price2.packagename_2:""}</div>
                                                        <p>Procedure Involved:</p>
                                                        <ul className='packageUL'>
                                                        
                                                                <i class="bi bi-check2-circle">
                                                                    <li className='packageLI' >{Form.singleProduct?Form.singleProduct.price2.descrip_2:""} </li>
                                                                </i>
                                                          

                                                        </ul>
                                                    </div>
                                                    <div className='col-3 inclusion '>
                                                        Inclusions:<br /><br />
                                                        <ul>
                                                                <li >{Form.singleProduct?Form.singleProduct.price2.inclusion_2:""} </li>
                                                        </ul>
                                                    </div>
                                                    <div className='respriceStyle col-3'>
                                                        <span className='packagePrice'> Price:-
                                                        {Form.singleProduct?Form.singleProduct.price2.price_2:""}<br /><br />
                                                        </span>
                                                        <Button onClick={() => clickthefunction2(update)} variant="success" className='singlebs'>Select Package</Button>
                                                    </div>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 start-here'>
                            <h2 className='t'>
                                <span className='cust-back backfrompackage'>Order Summery</span></h2>
                            <div className='container viewSummeryStyle'>
                                <div>city:<b>{Form.singleProduct?Form.singleProduct.location : ""}</b></div>
                                <br />
                                <div>Pooja Name: <b>{Form.singleProduct.title}</b></div><br />
                                <div>Package Name: <b>{packages1}{packages2}</b></div><br />
                                <div>Priest Preference:<b>{Form.singleProduct?Form. singleProduct.language : ""}</b>	</div><br />
                                <div className='d-flex'>{contactInfo.date ? <div>Selected Date& Time:<b>{contactInfo.date}</b></div> : ''}	</div><br />
                            </div>
                            <hr />
                            {getPrice ? <div className='checkFormStyle '>
                                <b>Select Date For puja</b>
                                <form onSubmit={handleSubmit}>
                                    <div className='checkInputStyle'>
                                        <input type='date' name="date"
                                            onChange={handleChange}
                                            value={selectedDate} />
                                    </div>
                                    {/* <Button className='btn-success ' type="submit">Check Availability</Button> */}

                                    {/* <p>{errors.date}</p> */}
                                    <div className='checkInputStyle'>
                                        <input type='time' name="time"
                                            onChange={handleChange}
                                            value={contactInfo.time} />(Optional)
                                    </div>
                                </form>

                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Full"
                                            checked={priceOption === 'Full'}
                                            onChange={handlePriceChange}
                                        />
                                        Full price
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="Half"
                                            checked={priceOption === 'Half'}
                                            onChange={handlePriceChange}
                                        />
                                        Half price
                                    </label>
                                </div>
                                {isCheck ? (
                                    <p>Selected price: {updatedPrice}</p>
                                ) : (
                                    <p>Selected price: {updatedPrice}</p>
                                )}
                                <AddToCart update={update}
                                    contactInfo={contactInfo}
                                    updatedPrice={updatedPrice}
                                    packages1={packages1}
                                    packages2={packages2}
                                />
                            </div> : ''}
                        </div>
                        {/* <DateItem/> */}


                    </div>
                </div>
            </section>
            <Event />
        </>




    )
}

export default ViewPrice