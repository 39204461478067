import React from 'react'
import './csscomponents/events.css';
import monk from './image/monk.png'
import gayatri from './image/gayatri.png'
import Shivlinga from './image/shivlinga.png'
import ServiceHeader from './ServiceHeader';

const Events = () => {
  return (
    <>

    <ServiceHeader/>
    <div class="container-fluid top-bk"><span class="topspace"></span></div>
      <div className='psBackGro'>
       <div className='container eventpage'>
        <div className='row '>
        <h2 class="h2Style-ev">Upcoming Events</h2>
           <div className='col-4'>
           <div class="card">
                <img class="card-img-top" src={monk} alt="Card image cap" />
                <div class="card-body">
                  <h5 class="card-title">Event title</h5>
                  <p class="card-text">Some quick example text to build on the Event title and make up the bulk of the card's content.</p>
                  <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
           </div>
           <div className='col-4'>
           <div class="card">
            <img class="card-img-top" src={gayatri} alt="Card image cap" />
            <div class="card-body">
              <h5 class="card-title">Event title</h5>
              <p class="card-text">Some quick example text to build on the Event title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>

           </div>
           <div className='col-4'>
           <div class="card">
            <img class="card-img-top" src={Shivlinga} alt="Card image cap" />
            <div class="card-body">
              <h5 class="card-title">Event title</h5>
              <p class="card-text">Some quick example text to build on the Event title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>

           </div>

        </div>
        </div>
      </div>

    </>
  )
}

export default Events