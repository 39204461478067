import React, { useEffect, useState } from 'react'
import { useParams, NavLink, useNavigate } from 'react-router-dom'
import { useProductContext } from './context/Productcontext';
import ProductsData from './ProductsData';
import { Modal, Button, Card, } from 'react-bootstrap';
import { useCartContext } from "./context/cart_context";
import PageNavigation from './PageNavigation';
import AddToCart from './AddToCart';
import "./csscomponents/singleptroduct.css"
import SingleService from './SingleService';
import Checkdate from './Checkdate';
import Singledown from './Singledown';
import ServiceHeader from './ServiceHeader';
import ViewPrice from './ViewPrice';
import Event from './Event';
import axios from 'axios'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';





const Singleproduct = ({ product,image }) => {
  const [Show, setShow] = useState(false)
  const [buttonText, setButtonText] = useState('Select Pricing and Packages');
  const [checked, setChecked] = useState(false)
  const [amount, seTAmount] = useState(1)
  const [Alert, setAlert] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [formData,setFormData] = useState({

    location: '',
    langauge: '',


  })
  const { getSingleProduct, isSingleLoading, singleProduct } =
    useProductContext();
    console.log(singleProduct)
  // const {addToCart} =useCartContext();
  // const navigate = useNavigate()
  // console.log(checked)
  const { _id } = useParams();
  const API = `http://3.109.4.40:4000/api/product`;
  // console.log(singleProduct)
  useEffect(() => {
    getSingleProduct(`${API}/${_id}`);

  }, []);



  // console.log(singleProduct)


  // const ShowAlert = (message, type) => {
  //   setAlert({
  //     msg: message,
  //     type: type
  //   })
  // }
  // if(getPrice){

  //   ShowAlert("please Enter Price")
  // }

  const handleClose = () => setShow(false);

 


  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };




  const navigate=useNavigate()
const handleChange=(event)=>{
  const name=event.target.name;
  const value=event.target.value;
  setFormData({...formData,[name]:value})
  console.log(formData)
}

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    const response = await axios.get(`http://3.109.4.40:4000/api/product?location=${formData.location}&language=${formData.langauge}`)
    navigate('/pujaservices', { state: response.data })
    console.log(response)

  } catch (error) {


    console.error(error)
  }

}

  let viewPrice = {

    singleProduct: singleProduct,

  }

  console.log(singleProduct)
  return (
    <>
      <section className="all-productpage">
      <div className='SingleProductImage container' >
        <div className='row  breadcrumbStyle'>
          <div className='col-lg-4 col-sm-6 product-breadcrumbs'>
          </div>
        </div>
        <div className='container'>
          <form className='row py-5 singleRowStyle ' onSubmit={handleSubmit}>
            <div className='col-lg-4'>
              <div className="form-group rounded">
              <select className="form-control selectStyle" id="exampleFormControlSelect1" name="location"
                onChange={handleChange}
                value={formData.location}>
                <option value="" selected disabled className='optionStyle'>----Location----</option>
                <option className='optionStyle'>Bengaluru</option>
                <option className='optionStyle'>Coimbatore</option>
                <option className='optionStyle'>Chennai</option>
                <option className='optionStyle'>Hyderabad</option>
              </select>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className="form-group rounded">
              <select className="form-control selectStyle" id="exampleFormControlSelect1" name="langauge"
                onChange={handleChange}
                value={formData.langauge}>
                <option value="" selected disabled className='optionStyle'>----Language----</option>
                <option className='optionStyle'>Tamil</option>
                <option className='optionStyle'>Kannada</option>
                <option className='optionStyle'>Hindi</option>
                <option className='optionStyle'>English</option>
                <option className='optionStyle'>Malayalam</option>
                <option className='optionStyle'>Telagu</option>
            
              </select>
              </div>
            </div>
            <div className='col-lg-4'>
              <button className=' rounded btn btn-warning font-weight-bold submitStyle  ml-auto' >Find Best Service</button>
            </div>
          </form>
        </div>
      </div>
      </section>

      <section className='container-fluid SingleProductSecond'>
        <div className='container sing-prodct'>
          <div className='row drdivStyle'>
            <div className='descdiv col-lg-8  col sm-12'>
              {singleProduct ? <div className='DespStyle  '>
                {singleProduct ? <p className='psStyle'> {singleProduct.title}</p> : ""}
                {/* <div className='product-price'>
                  Price Range:- {singleProductData ? <p> Rs: {singleProductData.price_range1}</p> : ""} <span className='price-gp'>to</span>{singleProductData ? <p> Rs:{singleProductData.price_range2}</p> : ""}
                </div> */}
                <div className='pujasinge-desc'>
                {singleProduct.description}
                </div>

                <div className='whenandwhy'>

                  <h4 className='pkd-det'>key Insights:</h4>
                      <ul className='key-imd'>
                        <i class="bi bi-check2-circle"><li className='ldm'>{singleProduct ? <p > {singleProduct.keyinsight}</p> : ""}</li></i>
                      </ul> 
                </div>
                <div className='ourpromise'>
                  <h4 className='pkd-det'>Our Promise:</h4>
                <ul className='key-imd'>
                  <i class="bi bi-check2-circle"><li className='ldm'>{singleProduct ? <p > {singleProduct.promise}</p> : ""}</li></i>
                </ul>
                </div>

                {/* {singleProductData ? <div><AddToCart product={singleProductData} getPrice= {getPrice} /></div> : ''} */}
              </div> : ""}

              <div className='location-description'>
                <span>
                  <div>Pooja will be performed in:{singleProduct ? <b >{singleProduct.location}</b>:""}</div>
                </span>
              </div>
              <div className='locationselection'>
                <NavLink to="/pujapackages" state={{ Form: viewPrice }}>
                  <button className="viewprice-package">Select Pricing and Packages </button>
                </NavLink>
              </div>
            </div>
            <div className='imageDiv1 col-lg-4 '>
              <div className='single_product_left1'>
                <div className='imagessingle1'>
                  <div className='pic1'>
                    {singleProduct ? <div className='spiStyle'><img src={singleProduct.image} /></div> : ''}
                  </div>
                </div>
                <div className='review-p'>
                  <div className='psoc'>
                    <a href='#' className='picon'><i class="bi bi-facebook"></i></a>
                    <a href='#' className='picon'><i class="bi bi-instagram"></i></a>
                    <a href='#' className='picon'><i class="bi bi-linkedin"></i></a>
                    <a href='#' className='picon'><i class="bi bi-whatsapp"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='single-product-tabs'>
      <Singledown singleProduct={singleProduct} />
            </section>
        <Event />
    </>
    
  )
}

export default Singleproduct