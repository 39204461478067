import React from 'react'
import { NavLink } from 'react-router-dom';
import './csscomponents/navnar.css';
import logoP from './image/logp.png';
import monk from './image/monk.png'
import wp from './image/whatsapp.png';
import cart from './image/cart1.png';
import { useCartContext } from './context/cart_context';

const Navbar = () => {

  const{total_item}=useCartContext()
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent navStyle " id="masthead">
      <div className="container-fluid">
        <NavLink className="navbar-brand novo" to="/">
        <img src={logoP} alt="logo" />
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 nova">
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/pujaservices">Puja Services</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="events">Events</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="blog">Blog</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="enquiry">Enquiry</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link login" to="login">Login</NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="contact" >
              <img src={wp} alt="wp" style={{ height: "30px", marginTop: '3px' }} />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="cart">
              <img src={cart} alt="wp" style={{ height: "35px" }} /> 
              <span  class='badge badge-warning' id='lblCartCount'>{total_item}</span>
              </NavLink>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  )
}

export default Navbar