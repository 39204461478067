import React, { useEffect, useState } from 'react'
import './csscomponents/checkdate.css'
import { useFormik } from 'formik';
import { signUpSchema } from './schemas';
import { Button } from 'react-bootstrap';
import ServiceHeader from './ServiceHeader'
import { useCartContext } from './context/cart_context';
import { NavLink, useLocation } from 'react-router-dom';
import AddToCart from './AddToCart';
const initialValues = {

    date: '',
    time: '',

}
const DateItem = () => {
    const { product, getPrice, packages } = useCartContext()
    let location = useLocation();
    const { Form } = location.state || {}
    console.log(Form)
    // const[click,isClick]=useState(true)
    // const { get_Date } = useCartContext()
    const [contacts, updateContacts] = useState([]);
    // console.log(get_Date)
    // console.log(contacts)

    const [contactInfo, setContactInfo] = useState({
        date: "",
        time: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setContactInfo({ ...contactInfo, [name]: value });
    };


    const addContact = (contactInfo) => {
        updateContacts([...contacts, contactInfo]);
    };


    const handleSubmit = (event) => {
        // prevents the submit button from refreshing the page
        event.preventDefault();
        if (contactInfo.date) {
            addContact(contactInfo)
            setContactInfo({ date: "", time: "" })
            console.log(contacts)
            console.log(addContact)
            alert('This Puja is Avaliable')

        } else {

            alert('please fill Date')
        }
        // console.log(contactInfo);

    };

    // useEffect(()=>{

    //     addContact()

    // },[])


    for (var i = 0; i < contacts.length; i++) {

    }


    return (
        <>

            <ServiceHeader />
            <div className='cartRowGrounds'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 datecheck'>
                            <form className='checkFormStyle ' onSubmit={handleSubmit} >
                                <div className='d-flex'>
                                    <div className='checkInputStyle'>
                                        <input type='date' name="date"
                                            onChange={handleChange}
                                            value={contactInfo.date} />
                                    </div>
                                    <Button className='btn-success DateItemStyleBtn' type="submit">Check Availability</Button>
                                </div>
                                {/* <p>{errors.date}</p> */}
                                <div className='checkInputStyle'>
                                    <input type='time' name="time"
                                        onChange={handleChange}
                                        value={contactInfo.time} />(Optional)
                                </div>
                                <div className='checkoption'>
                                    <input class="form-check-input"
                                        type="checkbox" id="checkbox1" name="option1" value="good" />
                                    <label class="form-check-label"> Select Date
                                        <input type='date' name="date"
                                            onChange={handleChange}
                                            value={contactInfo.date} />

                                    </label><br />

                                    <label class="form-check-label"> Select Time
                                        <input type='time' name="time"
                                            onChange={handleChange}
                                            value={contactInfo.time} />(Optional)
                                    </label><br />
                                    <span>OR</span><br />
                                    <input class="form-check-input"
                                        type="checkbox" id="checkbox1" name="option1" value="good" />
                                    <label class="form-check-label"> First Option </label>

                                </div>
                            </form>
                        </div>
                        <div className='col-lg-4 colStyleDate4'>
                            <div className='rightdate'>
                                Pooja Name:-  <b>{Form.title}</b><br />
                                Price:- <b>{Form.getPrice}</b><br />
                                Package Name:-<b> {Form.packages}</b>

                                {contacts ? contacts.map((val) => {
                                    return (
                                        <div> Date & Time:<b>{val.date}</b></div>
                                    )
                                }) : ""}
                            </div>
                        </div>
                        <AddToCart id={Form.id} title={Form.title}
                            imgurl={Form.imgurl}
                            getPrice={Form.getPrice}
                            packages={Form.packages} count={Form.count}
                            contactInfo={contacts[0]} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default DateItem