import React from 'react'
import Footer from './Footer';
import Herosection from './Herosection';
import Navbar from './Navbar'

const Header = () => {
  return (
 <>
 <Navbar/>

 </>
  )
}

export default Header;