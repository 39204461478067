import React from 'react'
import { NavLink } from 'react-router-dom';
import FormatPrice from './Helpers/FormatPrice';
import './csscomponents/product.css'
function Mainpujaserices({ _id, image, title, Description, price, featured, stock }) {
  return (
  
      <div className='col-lg-3  col-sm-12 pColStyle'>
        <div className='card pCardStyle '>
          <NavLink to={`/singleproduct/${_id}`}>
            <figure>
              <img src={image} alt={title} className='pImageStyle' />
            </figure>
          </NavLink>
          <NavLink to={`/singleproduct/${_id}`} className='crddata'>
            <div className='classStyle'>
              {title}
            </div>
          </NavLink>
        </div>
      </div>
   
  )
}

export default Mainpujaserices
