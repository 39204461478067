import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './csscomponents/Login.css';
import Event from './Event';


function Login() {
  let navigate=useNavigate()
  const[email,setEmail]=useState('');
  const[password,setPassword]=useState("")
  const[error,setError]=useState('')

  const handleSubmit=async(event)=>{

    event.preventDefault();
    try{
      const response=await axios.post('http://3.109.4.40:4000/api/user/login',{email,password})
      const token=response.data.token;
      sessionStorage.setItem('token',token)
      navigate('/')

    }catch(error){
setError('invalid Email or password')
    }
  }
  return (
    <>
      <div className='login-top'>
      </div>
      <div className="container-fluid top-bk"><span className="topspace"></span></div>
      <section className='login-page-inside'>
        <div className="d-flex justify-content-center align-items-center">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div className="card bg-white shadow-lg">
                  <div className="card-body login-inside">
                    <form className="mb-3 mt-md-4" onSubmit={handleSubmit}>
                      <h3 className="logintit">Login</h3>
                      <p className="blwtxt">Please enter your login and password!</p>
                      <div className="mb-3">
                        <label for="email" className="form-label ">Email address</label>
                        <input type="email" value={email}
                        onChange={(e)=>setEmail(e.target.value)} className="form-control" id="email" placeholder="Enter Your Email"  required/>
                      </div>
                      <div className="mb-3">
                        <label for="password" className="form-label ">Password</label>
                        <input type="password" value={email}
                        onChange={(e)=>setPassword(e.target.value)} className="form-control" id="email" placeholder="Enter Your password"  required/>
                      </div>
                      <p className="small"><a className="ftxt" href="./forget-password">Forgot password?</a></p>
                      <div className="d-grid">
                        <button className="btn btn-outline-dark remd" type="submit">Login</button>
                      </div>
                    </form>
                    <div>
                      <p className="mb-0  text-center">Don't have an account? <br /><a href="./signup"
                        className="signupt">Please Book A Puja</a></p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Event />
    </>

  )
}

export default Login
