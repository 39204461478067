import React, { useState } from 'react'
import { useCartContext } from './context/cart_context'
import CartItem from './CartItem';
import "./csscomponents/cartItem.css"
import ServiceHeader from './ServiceHeader';
import Nocart from './Nocart';
import Event from './Event';

const Cart = () => {
  const { cart, clearCart } = useCartContext();
  // const [count, setCount] = useState(0)
  if (cart.length === 0) {

    return (
      <Nocart />
    )
  }
  // console.log(cart)
  // console.log(clearCart)

  return (
    <>
      <ServiceHeader />
      <div class="container-fluid top-bk"><span class="topspace"></span></div>
      <div className='container cart-inside'>
        <div className=' row'>
          {cart ? cart.map((curElem) => {
          
            return (
                <CartItem key={curElem.id}{...curElem} />
            )
          }) : " "}
        </div>
      </div>
      <Event />
    </>


  )
}

export default Cart