import React from 'react'
import './csscomponents/Contact.css'
import ServiceHeader from './ServiceHeader'
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { signUpSchema } from './schemas'
import Event from './Event';

const initialValues = {

    name: '',
    email: '',
    subject: '',
    message: ''
}

const Contact = () => {

    const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values) => {

        }
    });
    return (
        <>
            <ServiceHeader />
            <div class="container-fluid top-bk"><span class="topspace"></span></div>
            <section class="contact-secst">

                <div className='container'>
                    <div class="row ">
                        <div class="col-lg-6">
                            <div class="row">
                                <h2 class="contact-head">Contact us</h2>
                                <p class="con-txt">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
                                    a matter of hours to help you.</p>
                                <ul class="foomainUL">
                                    <li class="list-unstyled d-flex"><i class="bi bi-envelope"></i>
                                    <span class="fo-mail"> nandan@addpronetwork.com</span>
                                    </li><li class="list-unstyled d-flex"><i class="bi bi-telephone"></i><span class="fo-mail"> +91 96860 73073</span></li><li class="list-unstyled d-flex"><i class="bi bi-geo-alt"></i><span class="fo-mail"> Addpro Network Private Ltd. No. 222, 2nd Floor, 10″E” Main, 1st Block, HRBR Layout, Kalyan Nagar, Bangalore – 560043.</span></li></ul>

                            </div>
                            <div class="status"></div>
                        </div>
                        <div class="col-md-6">
                            <form onSubmit={handleSubmit} >
                                <div class="col-md-6">
                                    <div class="md-form mb-0">
                                        <input type="text" id="name" name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            class="form-control"
                                            placeholder='Your name' />
                                    </div><br /><br />
                                </div>
                                <p>{errors.name}</p>
                                <div class="col-md-6">
                                    <div class="md-form mb-0">
                                        <input type="text" id="email" name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            class="form-control"
                                            placeholder='Your email' />
                                    </div>
                                </div><br /><br />
                                <p>{errors.email}</p>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="md-form mb-0">
                                            <input type="text" id="subject" name="subject"
                                                value={values.subject}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                class="form-control"
                                                placeholder='please Put Subject' />
                                        </div>
                                    </div><br /><br /><br /><br />
                                </div>
                                <p>{errors.subject}</p>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="md-form">
                                            <textarea type="text" id="message" name="message"
                                                value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                rows="2"
                                                class="form-control md-textarea"
                                                placeholder='Your message'>
                                            </textarea>
                                        </div>
                                    </div><br /><br />
                                </div>
                                <p>{errors.message}</p>
                                <div class="text-center text-md-left">
                                    <Button class="btn btn-primary" style={{ marginTop: '5rem' }}>Send</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Event />

        </>
    )
}

export default Contact