import { createContext, useContext, useEffect, useReducer } from "react";
import LanguageData from '../LanguageData'
import reducer from '../reducer/LanguageReducer'
const languageContext = createContext()
const API = LanguageData
const initialState = {

    isLoading: false,
    isError: false,
    Language: [],

}

const LanguageProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)
    const getLanguage = (url) => {
        dispatch({ type: 'Language_loading' });
        try{
            const Language=url;
            // console.log(Language)
            dispatch({ type: "myLanguage", payload: Language })
        }catch(error){

            dispatch({ type: 'languageError' });
        }
        const res = url
        // console.log(res)
    }

    useEffect(() => {

        getLanguage(API);

    }, [])


    return <languageContext.Provider value={{ ...state, getLanguage }} >
        {children}
    </languageContext.Provider>

}

const useLanguageContext = () => {

    return useContext(languageContext)
}
export { LanguageProvider, languageContext,useLanguageContext }