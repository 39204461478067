import React, { useState } from 'react'
import './csscomponents/checkout.css'
import { useCartContext } from './context/cart_context';
import { useLocation } from 'react-router-dom';
import ServiceHeader from './ServiceHeader';
import Event from './Event';
import { Button } from 'bootstrap';
// import Serviceheader from './Serviceheader';

const Checkout = () => {
  let location = useLocation();
  const { Form } = location.state
  console.log(Form)
const[form,setForm]=useState({



  
})
const handleSubmit=(event)=>{
event.preventDefault()

}
  return (
    <>
      <ServiceHeader />
      <div className='cartInnerBackGround'>
        <div className='container'>
          <div className='underRow'>Returning customer? Click here to login</div>
          <form onSubmit={handleSubmit}>
            <div className='row roeStylessss'>
              <div className='col-lg-7 firstcolStyles'>
                <h3 style={{ textAlign: 'center' }}>Billing  Details</h3>
                <div className='inputStyle'>
                  <div className='inputStyles'>
                    <input type="text" name="firstname" placeholder='Enter FirstName' />
                  </div>
                  <div className='inputStyles'>
                    <input type="text" name="lastname" placeholder='Enter LastName' />
                  </div>
                </div>
                <div className='inputStyle'>
                  <div className='inputStyles'>
                    <input type="text" name="phone" placeholder='Enter PhoneNumber' />
                  </div>
                  <div className='inputStyles'>
                    <input type="text" name='alterphnumber' placeholder='Enter Alternet Contact' />
                  </div>
                </div>
                <div className='inputStyle'>
                  <div className='inputStyles'>
                    <input type="email" name='email' placeholder='Enter Your Email' />
                  </div>
                  <div className='inputStyles'>
                    <input type="text" name='city' placeholder='Enter Town/City' />
                  </div>
                </div>
                <div className=''>
                  <div className='inputStyless'>
                    <input type="text" name='street' placeholder='Enter Street Address' />
                  </div>
                </div>
                <div className='inputStyle'>
                  <div className='inputStyles'>
                    <select name='country'>
                      <option>Country/Region</option>
                    </select>
                  </div>
                  <div className='inputStyles'>
                    <select name='county'>
                      <option>state/county</option>
                    </select>
                  </div>
                </div>
                <div className='inputStyle'>
                  <div className='inputStyles'>
                    <input name="postal_code" type="text" placeholder='Enter Postal Code' />
                  </div>
                  <div className='inputStyles'>
                    <input type="text" name="community" placeholder='Enter community' />
                  </div>
                </div>
              </div>
              <div className='col-lg-5 secondColStyle'>
                <h3 style={{ textAlign: 'center' }}>Order</h3>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" colspan="2">Product</th>
                      <th scope="col">subTotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="2">
                        <div name="title">puja Name:{Form.title}</div>
                       <div name="package "> Package Name:<br/> {Form.packages1}{Form.packages2}</div>
                       <div name="date"> Puja Date:{Form.contacts.date}</div>
                      </td>
                      <td name="UpdatedPrice">Rs:{Form.updatedPrice}</td>
                    </tr>
                    <tr>
                      <td colspan="2">Subtotal</td>
                      <td name="UpdatedPrice">Rs:{Form.updatedPrice}</td>
                    </tr>
                    <tr>
                      <td colspan="2">Transaction Fee</td>
                    </tr>
                    <tr>
                      <td colspan="2">Total</td>
                    </tr>
                  </tbody>
                </table>
                <div class="payment_box_payment_method_razorpay">
                  <p>Pay securely by Credit or Debit card or Internet Banking through Razorpay.</p>
                </div>
                <hr/>
                <button className='btncheckstyle' type="submit">Place Order</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Event />
    </>
  )
}

export default Checkout