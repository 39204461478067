import React from 'react'
// import Serviceheader from './Serviceheader'
import './csscomponents/enquiry.css'
import ServiceHeader from './ServiceHeader'

const Enquiry = () => {
  return (
  
    <>
    

<ServiceHeader/>
    <div className='container-fluid enquirystyle'>
      
    </div>
    </>
  )
}

export default Enquiry