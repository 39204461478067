import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useCartContext } from './context/cart_context';
import "./csscomponents/cartItem.css";
import { NavLink } from 'react-router-dom'
import Checkout from './Checkout';

// var percentageGet = 50
const CartItem = ({ id, getPrice, packages, count, title, imgurl, updatedPrice,contacts,packages1,packages2}) => {
 
    const { product, cart, clearCart } = useCartContext();
    const { removeItem } = useCartContext()
    const [radios, setRadios] = useState()
    const [sRadios, setSradios] = useState()
    const [isset, setIsset] = useState(false)
    const [clicks, setClicks] = useState(false)

    let cartItemProps = {
        id: id,
        title: title,
        getPrice: getPrice,
        packages: packages,
        updatedPrice:updatedPrice,
        packages1:packages1,
        packages2:packages2,
        contacts:contacts
      
    }
    console.log(cartItemProps)
    return (
        <>
            <div className="col-sm-8">
                <h2><span class="cust-back backfrompackage">Cart Details</span></h2>
                <table class="table TableStyle" >
                    <thead >
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Item</th>
                            <th scope="col">Price</th>
                            <th scope="col">PackageName</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td ><Button onClick={() => removeItem(id)} className='cartdel-but'><i class="bi bi-trash3"></i></Button></td>
                            <td className='img-fluid  cartItemStyle'> <img src={imgurl} alt={id} /></td>
                            <td> Rs: {updatedPrice}</td>
                            <td> {packages1}{packages2}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-sm-4">
                <h2><span class="cust-back backfrompackage">Order Summery</span></h2>
                <div class="card cartc">
                    <div class="card-body ca">
                        {/* <table class="table" >
                            <thead >
                                <tr>
                                    <th scope="col">Item Name</th>
                                    <th scope="col"> Total Price</th>
                                    <th scope="col">PackageName</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td>{title}</td>
                                <td>{getPrice}</td>
                                <td>{packages}</td>
                            </tbody>
                        </table> */}

                        <div className='rightcart'>
                            Pooja Name:-  <b>{title}</b><br />
                            Price:- <b>{updatedPrice}</b><br />
                            Package Name:-<b> {packages1}{packages2}</b><br />
                            Seleted Date:- <b> {contacts.date}</b>
                        </div>
                        {/* 
                        {setClicks && radios}
                        {setClicks && sRadios} */}

                        { }
                        {/* {sRadios} */}
                        {/* <from >
                            <div class="inputGroup">
                                <input id="radio1" name="radio" type="radio" onClick={() => firstRadio(id)} />
                                <label for="radio1">FullPayment</label>
                            </div>
                            <div class="inputGroup">
                                <input id="radio2" name="radio" type="radio" onClick={() => secondRadio(id)} />
                                <label for="radio2">Advance 50% of price</label>
                            </div>
                        </from> */}

                        <NavLink to='/checkout' state={{ Form: cartItemProps }}><Button className='check-but'>
                            proceed to checkout
                        </Button></NavLink>
                    </div>
                </div>
            </div>

            {/* <tr>
                <td className='img-fluid  cartItemStyle'> <img src={image} alt={id} /></td>
                <td>  {getPrice}</td>
                <td ><Button onClick={() => removeItem(id)} > delete</Button></td>
            </tr> */}

        </>




    )
}

export default CartItem;