import React, { useEffect, useState } from 'react'
import { useLanguageContext } from './context/LanguageContext'
import { useLocationContext } from './context/LocationContext'
import { useProductContext } from './context/Productcontext'
import './csscomponents/Hero.css'
import { useFormik } from 'formik';
import LocationData from './LocationData';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Herosection = () => {
  const { product } = useProductContext()
  console.log(product)
  const [formData, setFormData] = useState({

    location: '',
    langauge: '',
    Service: '',

  })
  const navigate = useNavigate()
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({ ...formData, [name]: value })
    console.log(formData)
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.get(`http://3.109.4.40:4000/api/product?location=${formData.location}&language=${formData.langauge}`)
      navigate('/pujaservices', { state: response.data })
      console.log(response)

    } catch (error) {


      console.error(error)
    }

  }
  return (
    <div className='site-banner banner-images'>
      <div className='container'>
        <form className='row Banner-form container' onSubmit={handleSubmit} >
          <div className='col-lg-4  col-md-6 col-sm-12 heroColStyle'>
            <div className="form-group rounded">
              <select className="form-control selectStyle" id="exampleFormControlSelect1" name="location"
                onChange={handleChange}
                value={formData.location}>
                <option value="" selected disabled className='optionStyle'>----Location----</option>
                <option className='optionStyle'>Bengaluru</option>
                <option className='optionStyle'>Coimbatore</option>
                <option className='optionStyle'>Chennai</option>
                <option className='optionStyle'>Hyderabad</option>
              </select>
            </div>
          </div>
          <div className='col-lg-4 heroColStyle'>
            <div className="form-group rounded">
              <select className="form-control selectStyle" id="exampleFormControlSelect1" name="langauge"
                onChange={handleChange}
                value={formData.langauge}>
                <option value="" selected disabled className='optionStyle'>----Language----</option>
                <option className='optionStyle'>Tamil</option>
                <option className='optionStyle'>Kannada</option>
                <option className='optionStyle'>Hindi</option>
                <option className='optionStyle'>English</option>
                <option className='optionStyle'>Malayalam</option>
                <option className='optionStyle'>Telagu</option>

              </select>
            </div>
          </div>
          <div className='col-lg-4 heroColStyle'>
            <div className="form-group rounded ">
              <select className="form-control selectStyle" id="exampleFormControlSelect1" name="Service"
                onChange={handleChange}
                value={formData.Service}>
                <option value="" selected disabled className='optionStyle'>----Service----</option>
              </select>
            </div>
          </div>
          <button type="submit" className='my-2 rounded btn search-but font-weight-bold submitsStyle   hbrStyle' >Find Best Service</button>
        </form>
        <h5 className='bdwn-txt'>Lookup for best Pujari & Puja services near you!</h5>
      </div>

    </div>

  )
}

export default Herosection