import React from 'react'
import { useProductContext } from './context/Productcontext';
import './csscomponents/PujaServices.css'
import { NavLink, useLocation, useParams } from 'react-router-dom';
import Product from './Product';
import Vedayatraworks from './Vedayatraworks';
import SingleService from './SingleService';
import ServiceHeader from './ServiceHeader';
import Customerreview from './Customerreview';
import Mainpujaserices from './Mainpujaserices';
import Event from './Event';
import { useEffect } from 'react';

const Pujaservices = () => {
  const { product, getFilteredProduct } =
    useProductContext();
  // const { location,langauge } = useParams();
  // const API = `http://3.109.4.40:4000/api/product`;
  // useEffect(() => {
  //   getFilteredProduct(`${API}/?location=${location}/&language=${langauge}`);
  // }, []);

  const { state } = useLocation();
  console.log(state)
  return (
    <>
      <ServiceHeader/>
      <div className='container-fluid psbanner-services'>
        {/* <div className='backgroundImage'> */}
        <div className='container servprod'>
          <div className='row'>
            {state ? state.map((currElem) => {
              return (
                // <Product key={currElem.id} {...currElem} />
                <Mainpujaserices key={currElem.id} {...currElem} />
              )
            }) : Product ? product.map((val) => {
              return (
                // <Product key={currElem.id} {...currElem} />
                <Mainpujaserices key={val.id} {...val} />
              )

            }) : ""}
          </div>
        </div>
        {/* </div> */}
      </div>
      <Customerreview />
      {/* <SingleService /> */}
      {/* <Vedayatraworks /> */}
      <Event />
    </>
  )
}

export default Pujaservices