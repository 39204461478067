import { createContext, useContext, useEffect, useReducer } from "react";
import LocationData from '../LocationData'
import reducer from '../reducer/LocationReducer'
const LocationContext = createContext()
const API = LocationData
const initialState = {

    isLoading: false,
    isError: false,
    location: [],

}

const LocationProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)
    const getLocation = (url) => {
        dispatch({ type: 'Location_loading' });
        try{
            const location=url;
            dispatch({ type: "myLocation", payload: location })
        }catch(error){

            dispatch({ type: 'locationError' });
        }
        const res = url
        console.log(res)
    }

    useEffect(() => {

        getLocation(API);

    }, [])


    return <LocationContext.Provider value={{ ...state, getLocation }} >
        {children}
    </LocationContext.Provider>

}

const useLocationContext = () => {

    return useContext(LocationContext)
}
export { LocationProvider, LocationContext,useLocationContext }