import React from 'react'
import './csscomponents/blog.css'
import ServiceHeader from './ServiceHeader'
const Blog = () => {
  return (
    <>
<ServiceHeader/>
    <div className='container-fluid BlogBackGround2'>

    </div>
  </>
  )
}

export default Blog