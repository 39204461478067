import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useProductContext } from './context/Productcontext'
import './csscomponents/Singledown.css'
import Description from './Description'
import Faq from './Faq'
import Reviews from './Reviews'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
const Singledown = ({ singleProductData }) => {
    // const{product}=useProductContext()
    console.log(singleProductData)
    return (
        <>
            <div className='container srp'>
                <div className='row singleDown'>
                    <div className='col-lg-8 product-main-tab'>
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="nav nav-tabs nav-justified prd-tabs product-tabs" >
                            <Tab eventKey="home" title="Description" className="nav-link">
                                <div className="tab-content product-tab-content">
                                    {/* {singleProductData ? singleProductData.desc.map((val) => {
                                        return (
                                            <div className='inside-cont'>
                                                {val.Desc1}
                                                {val.Perform}
                                                {val.Benefits}
                                            </div>
                                        )
                                    }) : ""} */}
                                    {/* <p>This is the home tab.</p> */}
                                </div>

                            </Tab>


                            <Tab eventKey="profile" title="Reviews" className="nav-link product-tabs">
                                <div className="tab-content product-tab-content">
                                    {/* {singleProductData ? singleProductData.reviews.map((val) => {
                                        return (
                                            <div>
                                                {val.reviews1}
                                            </div>
                                        )
                                    }) : ""} */}
                                </div>
                            </Tab>


                            <Tab eventKey="contact" title="FAQ" className="nav-link product-tabs">
                                <div className="tab-content product-tab-content">
                                    <p>Can I pay a Partial amount in advance to confirm the Ceremony and pay the balance as cash or pay online after the Ceremony? </p>
                                    {singleProductData ? singleProductData.Faq.map((val) => {
                                        return (

                                            <div className='col-lg-8 inside-tabs'>
                                                {/* <li>{val.faq1}</li> */}
                                                {val.faq1}<br /><br />
                                                How long does the Ceremony take to complete?
                                                {val.faq2}
                                            </div>
                                        )
                                    }) : ""}
                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                    <div className='col-lg-4 Product-enquiry'>
                        <div className='pmd'>
                        <span className='enq-resp'>Finfing difficulty to follow the Booking Process Then Please Fill the Below Form to reach us</span>
                        </div>
                        <Form>
                            <Form.Group className='single-prod-enq'>
                                <Form.Label className='fm-enq'>Enter your full name:</Form.Label>
                                <Form.Control type="text"
                                    placeholder="Enter your full name" className='fm-enq-pl'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='fm-enq'>Enter your email address:</Form.Label>
                                <Form.Control type="email"
                                    placeholder="Enter your your email address" className='fm-enq-pl'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='fm-enq'>Puja you want to perform:</Form.Label>
                                <Form.Control type="text" placeholder="Puja you want to perform" className='fm-enq-pl'/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='fm-enq'>Enter Mobile Number:</Form.Label>
                                <Form.Control type="Number" placeholder="Enter Mobile Number" className='fm-enq-pl'/>
                            </Form.Group>
                            <Button variant="primary" type="submit" className='enq-form'>
                                Click here to submit form
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Singledown