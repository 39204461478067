import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useCartContext } from './context/cart_context';
import './csscomponents/AddToCart.css';
import { useNavigate } from 'react-router-dom';

const AddToCart = ({
  update,contactInfo,updatedPrice,packages1,packages2
}) => {
  const { cart, addtoCart } = useCartContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();

  console.log(update)
  console.log(contactInfo)
  console.log(updatedPrice)
  // useEffect(() => {
  //   setSelectedDate(contactInfo);
  // }, [contactInfo]);

  const { date } = contactInfo;
  console.log(date)
  console.log(updatedPrice)
  // console.log(selectedValue)


  const handleClick = (event) => {
    if (date&&updatedPrice) {
      event.preventDefault();
      addtoCart(
        update,contactInfo,updatedPrice,packages1,packages2
      );
      navigate('/cart');
    } else {
      event.preventDefault();
      alert("please select Date and payment format for better convience")
    }

    // console.log(selectedDate)


  };


  return (
    <div className="container AddTOCartStyle">
      <NavLink to="/cart" onClick={handleClick}>
        <Button className="CartAcdStyle">
          Proceed To Cart
          {/* <span>>></span> */}
        </Button>
      </NavLink>
    </div>
  );
};

export default AddToCart;