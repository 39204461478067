import React from 'react'
import './csscomponents/vedayatraWorks.css';
import kalas from './image/smallkalas.png';
import swastik from './image/swastik.png';
import babaji from './image/babaji.png'
import bar from './image/bar.png'
import arrow from './image/arrow.png'
const Vedayatraworks = () => {
    return (
        <>
            <div className='container headings'>
                <div className='main_body'>
                    <h2 className='h2Style-2' >How Veda Yatra works?</h2>
                </div>

                <div className='main_paragraph'>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit, sed quia consequuntur magni dolores eos qui
                        ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                </div>
                <div className='row nextsection'>
                    <div className="col-lg-4 col-md-12 hsssStyle">

                        <div className="card card_body-work">

                            <div className="card-block">

                                <div className='inner_cardbody'>

                                    <h4 className="card-title innercard_title "><span className='letter'>1</span></h4>

                                </div>

                                <div className='inner_inner_card' >

                                    <img src={kalas} alt='' />

                                </div>

                                <div className='inner_inner_body-work'>

                                    <h6 className='inner_inner_header' >Select Your Puja</h6>
                                    <div className='imagess'><img src={bar} alt='' /></div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="col-lg-4 col-md-12 vwStyle hsssStyle">

                        <div className="card card_body-work">

                            <div className="card-block">

                                <div className='inner_cardbody'>

                                    <h4 className="card-title innercard_title "><span className='letter'>2</span></h4>

                                </div>

                                <div className='inner_inner_card' >

                                    <img src={swastik} alt='' />

                                </div>

                                <div className='inner_inner_body-work'>

                                    <h6 className='inner_inner_header' >Book a Date</h6>
                                    <div className='imagess'><img src={bar} alt='' /></div>


                                </div>
                                <div className='arrow'>
                                    <img src={arrow} alt='' />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 vwsStyle">

                        <div className="card card_body-work" >

                            <div className="card-block">

                                <div className='inner_cardbody'>

                                    <h4 className="card-title innercard_title "><span className='letter'>3</span></h4>

                                </div>

                                <div className='inner_inner_card' >

                                    <img src={babaji} alt='' />

                                </div>

                                <div className='inner_inner_body'>

                                    <h6 className='inner_inner_header' >Track your Puja</h6>
                                    <div className='imagess'><img src={bar} alt='' /></div>

                                </div>
                                <div className='arrow'>
                                    <img src={arrow} alt='' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        

        </>
    )
}

export default Vedayatraworks