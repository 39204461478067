import React from 'react';
import './csscomponents/event.css';
import midflower from './image/midflower.jpg';
import monk from './image/monk.png';
import gayatri from './image/gayatri.png';
import Shivlinga from './image/shivlinga.png';
import { envelope } from 'bootstrap';
const Event = () => {
    return (
        <>
            <section className='footer'>
                <div className='container' >
                    <img src={midflower} alt='midflower1' className='imageclass' />
                    <h2 className='h2Style-2' >Upcoming Events</h2>
                    <div className='mains  row container' >
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className="card  editable_card">
                                <img className="card-img-top bg-none" src={monk} alt="Carimagecap" />
                                <h4 className="card-title innercard_title_title "><span className='letters'>18<br /> Nov</span></h4>
                                <div className="card-body">
                                    <h5 className="card-title c_card">Guru Purnima</h5>
                                    <p className="card-text c_text">Sed porttitor volutpat finibus. Aliquam dolor massa, commodo quis malesuada a, semper ut quam. Fusce quis ultrices lectus. Duis porta sed nibh vel congue.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className="card  editable_card">
                                <img className="card-img-top" src={gayatri} alt="Card image cap" />
                                <h4 className="card-title innercard_title_title "><span className='letters'>18<br /> Nov</span></h4>
                                <div className="card-body">
                                    <h5 className="card-title c_card">Gayatri Jayanti</h5>
                                    <p className="card-text c_text ">Sed porttitor volutpat finibus. Aliquam dolor massa, commodo quis malesuada a, semper ut quam. Fusce quis ultrices lectus. Duis porta sed nibh vel congue.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className="card  editable_card">
                                <img className="card-img-top" src={Shivlinga} alt="Cardimagecap" />
                                <h4 className="card-title innercard_title_title "><span className='letters'>18<br /> Nov</span></h4>
                                <div className="card-body">
                                    <h5 className="card-title c_card">Shravan Mass</h5>
                                    <p className="card-text c_text">Sed porttitor volutpat finibus. Aliquam dolor massa, commodo quis malesuada a, semper ut quam. Fusce quis ultrices lectus. Duis porta sed nibh vel congue.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
               
            </section>
        </>
    )
}

export default Event