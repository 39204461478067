export default [
    
         
    {"city_id":"Bangalore","sortName":"Bang","city_name":"Bangalore"},
    {"city_id":"Bhubaneswar","sortName":"Bbsr","city_name":"Bhubaneswar"},
    {"city_id":"Chennai","sortName":"che","city_name":"Chennai"},
    {"city_id":"Coimbatore","sortName":"comb","city_name":"Coimbatore"},
    {"city_id":"Delhi","sortName":"Del","city_name":"Delhi"},
    {"city_id":"Hyderabad","sortName":"Hyd","city_name":"Hyderabad"},
    {"city_id":"Indore","sortName":"Indor","city_name":"Indore"},
    {"city_id":"Kolkata","sortName":"Cal","city_name":"Kolkata"},
    {"city_id":"Lucknow","sortName":"Luk","city_name":"Lucknow"},
    {"city_id":"Mumbai","sortName":"Mum","city_name":"Mumbai"},
    {"city_id":"Pune","sortName":"Pun","city_name":"Pune"},
    {"city_id":"Visakhapatnam","sortName":"Vk","city_name":"Visakhapatnam"},
       

    
]






