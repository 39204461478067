import React from 'react'
import ServiceHeader from './ServiceHeader'
import './csscomponents/nocart.css'
import { NavLink } from 'react-router-dom'
const Nocart = () => {
    return (
        <>
            <ServiceHeader />
            <div class="container-fluid top-bk"><span class="topspace"></span></div>
            <div className=' container cart-cont'>
            <div className='row'>

                <p class="psStyle">There Is No Pujas in Cart</p>
                <NavLink to='/pujaservices' className='c-sel'>Please Select a Puja>></NavLink>
                </div>
            </div>
        </>
    )
}

export default Nocart