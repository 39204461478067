import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import './bootstrap/css/bootstrap.min.csc
import 'bootstrap/dist/css/bootstrap.min.css'
import About from './About';
import './App.css';
import Home from './Home';
import Product from './Product';
import Contact from './Contact';
import Singleproduct from './Singleproduct'
import Cart from './Cart'
import Error from './Error';
import Pujaservices from './Pujaservices';
import Blog from './Blog';
import Enquiry from './Enquiry';
import Event from './Event';
import Header from './Header';
import Events from './Events';
import Footer from './Footer';
import Checkout from './Checkout';
import Date from './Date';
import Description from './Description';
import Faq from './Faq';
import Reviews from './Reviews';
import ViewPrice from './ViewPrice';
import Login from './Login';
import Userpujatracker from './Userpujatracker';
import PrivateRoute from './utils/PrivateRoute';
import ScrollToTop from './ScrollToTop';

// import Herosection from './Herosection';
function App() {
  return (
    <BrowserRouter >
    <ScrollToTop/>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/product' element={<Product />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/singleproduct/:_id' element={<Singleproduct />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/pujaservices' element={<Pujaservices />} />
        <Route path='/Date' element={<Date />} />
        <Route path='/events' element={<Events />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/enquiry' element={<Enquiry />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/pujapackages' element={<ViewPrice />} />
        <Route path='/login' element={<Login />} />
        <Route path='/track-puja' element={<Userpujatracker />} />


        {/* <Route path='/description' element={<Description/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/reviews' element={<Reviews/>}/> */}
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
