import { ReactDOM } from 'react-dom';
import React, { useEffect, useState } from 'react';
import banner from './image/banner.png'
import logo2 from './image/logo02.png'
import './csscomponents/customerreview.css'
import reviews from './reviews.json'
import { Modal, Button, FloatingLabel } from 'react-bootstrap'
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import reviewimgurl from './image/review.png'

import Vedayatraworks from './Vedayatraworks';
import axios from 'axios';
// import  signUpSchema  from "./schemas";


const Customerreview = () => {
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(false)

  const [isShow, invokeModal] = React.useState(false)
  const initModal = () => {
    invokeModal(!isShow)
  }
  const initialValues = {
    name: "",
    pujari_name: "",
    date: "",
    exp_description: ""

  }
  const { values, errors, handleBlur, handleSubmit, handleChange } =
    useFormik({
      initialValues,
      // validationSchema: signUpSchema,
      onSubmit: async (values, action) => {
        try {
          const userResponse = await axios.post('http://3.109.4.40:4000/api/customerreview/add')
          userResponse(initialValues)
        } catch (error) {
          console.log(error)
        }
        action.resetForm();
      },
    });
  let didCancel = false
  const getReviews = async () => {
    setLoading(true)
    try {
      const response = await axios.get('http://3.109.4.40:4000/api/customerreview')
      console.log(response.data.Customerreview)
      if (!didCancel) {
        setReviews(response.data.Customerreview.slice(0,3))

      }
    } catch (error) {

      console.error(error)
    }

    setLoading(false)
  }

  useEffect(() => {
    getReviews()
    return () => {
      didCancel = true
    }
  }, [])
  return (
    <section className="review-section">
      <div className='container customerMain'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <figure className='secondFig'>
              <img src={logo2} alt='bannner' />
            </figure>
            <div className='header-rvw'>
              <h2 class="h2Style-white">Customer Reviews </h2>
            </div>
            <div className='row rowstyle rev-cont'>
              {reviews.map(review => {
                return (
                  <div key={review.id} className='col-lg-4 '  >
                    <div className="card card-revw" style={{ border: "none", background: "none" }}>
                      <img src={reviewimgurl} className='imageStyle' />
                      <p className='paragraphStyle-desc'>{review.Description}</p>
                      <p className='paragraphStyle-date'>{review.customer},{review.date}</p>
                      <p className='paragraphStyle-user'>Puja By<br />{review.pandit}</p>
                    </div>
                  </div>

                );
              })}
            </div>
            <h5 className='h4Style'>Are you our prestigious customer, if yes</h5>
            <div className='customerbutton'>
              <button type="button" className="btn btn-warning cBtn1" onClick={initModal}><span className='but-Text'>Submit Your Experience</span></button>
            </div>
          </div>
          <Modal show={isShow}>
            <Modal.Header closeButton onClick={initModal}>
              <Modal.Title>Happy to  hear from you</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <FloatingLabel
                  controlId="floatingTextarea"
                  label="Give your Experience Thoughts"
                  className="mb-3"
                  name='exp_description'
                  values={values.exp_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <Form.Control as="textarea" placeholder="Leave a comment here" />
                </FloatingLabel>

                <Form.Control
                  type="text"
                  id="inputPassword5"
                  placeholder='Enter your Name'
                  name="name"
                  aria-describedby="passwordHelpBlock"
                  values={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /><br />
                <Form.Control
                  type="date"
                  id="inputPassword5"
                  name="date"
                  aria-describedby="passwordHelpBlock"
                  values={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                // {moment().format("MMMM Do YYYY")}
                /><br />

                <Form.Control
                  type="text"
                  id="inputPassword5"
                  placeholder='Enter Pujari Name '
                  name='pujari_name'
                  aria-describedby="passwordHelpBlock"
                  values={values.pujari_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /><br />
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="danger" onClick={initModal}>
            Close
          </Button> */}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  )
}

export default Customerreview