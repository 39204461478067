import React from 'react'
import img1 from './image/kalasha.png'
import img2 from './image/homa.png'
import img3 from './image/hindu.png'
import './csscomponents/innerhero.css'
const InnerHeroSection = () => {
    return (
<>
    <div className='container-fluid top-bk'>
       <span className='topspace'></span>
    </div>    
    <div className='container'>
        <div className='row'>
        <div className='col-lg-4  col-sm-12 colstyle' >
            <div>
                <img src={img1} alt="aboutImg1" className='fet-img'/>
            </div>
            <div>
                <h1 className='hStyle '>25000+</h1>
            </div>
            <div>
                <p className='hStyle-para '>Pujas Performed</p>
            </div>
        </div>
        <div className='col-lg-4  col-sm-12 colstyle' >
            <div>
                <img src={img2} alt="aboutImg1" className='fet-img'/>
            </div>
            <div>
                <h1 className='hStyle '>450+</h1>
            </div>
            <div>
                <p className='hStyle-para'>Pujas and Homas</p>
            </div>
        </div>
        <div className='col-lg-4  col-sm-12 colstyle' >
            <div>
                <img src={img3} alt="aboutImg1" className='fet-img'/>
            </div>
            <div>
                <h1 className='hStyle '>1800+</h1>
            </div>
            <div>
                <p className='hStyle-para'>Pujari is onboard</p>
            </div>
        </div>
      </div>
      <h2 className='h2Style' >About Veda Yatra</h2>
            <p className='pStyle container'>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaevcat cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
                quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
                porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
                aliquam quaerat voluptatem.</p>
    </div> 
   
</>

    )
}

export default InnerHeroSection