export default [
    {
        "id": 1,
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/ganesh-puja.jpg",

        "title": "Maha Ganapati Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],

        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],
        "price_range1": "1000",
        "price_range2": "2000",


        "Description": "Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully. ",
        "price": [{

            "price1": 1000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],

            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]
        },
        {

            "price1": 2000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        }],
        "count": 1,
        "featured": true,
        "count": 1,
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ]

        ,
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 2,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/vishnu.jpg",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/vistnu-puja.jpg",
        "title": "Satyanarayan Puja  ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],
        "price_range1": "3000",
        "price_range2": "4000",
        "Description": "Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully. ",
        "price": [{

            "price1": 3000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        },
        {

            "price1": 4000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ],

        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]

    },
    {
        "id": 3,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/laxmi-maa.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/saraspati-puja.jpg",
        "title": "Lakshimi Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [{
            "promises1": "Vedic Patashala certified and experienced priests.",
            "promises1": "All rituals follow Vedic Standards and Procedures",
            "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.",
            "promises1": "Guaranteed Punctuality and Authenticity.",
            "promises1": "Professional Guidance & Support.",

        }],

        "price_range1": "5000",
        "price_range2": "6000",
        "Description": "Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully. ",
        "price": [{

            "price1": 5000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]


        },
        {

            "price1": 6000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]


        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 4,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/biswakarma1-1.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/biswakarma-puja.jpg",
        "title": "Viswakarma Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],

        "price_range1": "7000",
        "price_range2": "8000",
        "Description": " Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully.",
        "price": [{

            "price1": 7000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        },
        {

            "price1": 8000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 5,
        // 'status':false,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/bs.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/marring-puja.jpg",
        "title": "Marriage Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],
        "price_range1": "9000",
        "price_range2": "10000",
        "Description": "Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully. ",
        "price": [{

            "price1": 9000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]



        },
        {

            "price1": 10000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]


        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 6,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/bst.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/pongol-puja.jpg",
        "title": "Namkaran Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],
        "price_range1": "11111",
        "price_range2": "120000",
        "Description": " Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully.",
        "price": [{

            "price1": 11111,
            "addon": 100,
            " packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]
        },
        {

            "price1": 120000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 7,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/bgtm.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/kumbha-puja.jpg",
        "title": "Gruhpravesh Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],

        "price_range1": "13000",
        "price_range2": "14000",
        "Description": " Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully.",
        "price": [{

            "price1": 13000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        },
        {

            "price1": 14000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]


        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 8,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/bgtmm.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/Vehical-puja.jpg",
        "title": "Vehical Puja",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],

        "price_range1": "5000",
        "price_range2": "6000",
        "Description": " Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully.",
        "price": [{

            "price1": 5000,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]




        },
        {

            "price1": 6000,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        }],
        "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    },
    {
        "id": 9,
        // "imgurl": "http://localhost/ecom1/wp-content/uploads/2022/11/bgtms.png",
        "imgurl": "http://shubhopuja.addnetit.com/wp-content/uploads/2023/01/office-pija.jpg",
        "title": "Office Puja ",
        "key_insights": [
            { "key_insights1": "Baby writes for the first time with Lord's blessings.", },
            { "key_insights1": "It helps the baby to grow healthy and strong.", },
            { "key_insights1": "Performed on any auspicious day after the child attains 2.5 yrs of age..", },
            { "key_insights1": "Mantras are chanted for the baby’s well-being.", },
        ],
        "promises": [

            { "promises1": "Vedic Patashala certified and experienced priests.", },
            { "promises1": "All rituals follow Vedic Standards and Procedures", },
            { "promises1": "High-quality Samagri to ensure a pleasant Pooja experience.", },
            { "promises1": "Guaranteed Punctuality and Authenticity.", },
            { "promises1": "Professional Guidance & Support.", },

        ],

        "price_range1": "4500",
        "price_range2": "6250",
        "Description": "Aksharabhyasam is one of the 16 sanskaras to be performed, in which when the baby attains age two years and above will be initiated to write the first letter for the first time in order to get the blessings from Matha Saraswati and Lord Ganesh by performing the Aksharabhyasam pooja to start their schooling life successfully. ",
        "price": [{

            "price1": 4500,
            "addon": 100,
            "packagename": "Economy : ( 1 Priest + Pooja Samagries )",
            "Discountprice": 500,
            "inclusion": [
                { "includes1": " Dakshina" },
                { "includes1": "  All Pooja Materials" },

            ],
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]



        },
        {

            "price1": 6250,
            "addon": 100,
            "packagename": "Standard : (2 Priest + Saraswati & Hayagreeva Homam)",
            "Discountprice": 500,
            "includes1": " Dakshina",
            "includes2": "  All Pooja Materials",
            "description": [
                { "Description1": "Ganapathi Pooja" },
                { "Description1": "Punyaha Vachanam" },
                { "Description1": "Saraswati Pooja" },
                { "Description1": "Aksharabhyasam" },
                { "Description1": "Prasad Distribution" },
            ]

        }], "featured": true,
        "stock": 6,
        "count": 1,
        "Desc": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. ",
        "Language": [

            { "lan": "Tamil" },
            { "lan": "Telugu" },
            { "lan": "Hindi" },
        ],
        "locations": [
            { "city_id": "Bangalore", "sortName": "Bang", "city_name": "Bangalore" },
            { "city_id": "Bhubaneswar", "sortName": "Bbsr", "city_name": "Bhubaneswar" },
            { "city_id": "Chennai", "sortName": "che", "city_name": "Chennai" },
            { "city_id": "Coimbatore", "sortName": "comb", "city_name": "Coimbatore" },
            { "city_id": "Delhi", "sortName": "Del", "city_name": "Delhi" },
            { "city_id": "Hyderabad", "sortName": "Hyd", "city_name": "Hyderabad" },
            { "city_id": "Indore", "sortName": "Indor", "city_name": "Indore" },
            { "city_id": "Kolkata", "sortName": "Cal", "city_name": "Kolkata" },
            { "city_id": "Lucknow", "sortName": "Luk", "city_name": "Lucknow" },
            { "city_id": "Mumbai", "sortName": "Mum", "city_name": "Mumbai" },
            { "city_id": "Pune", "sortName": "Pun", "city_name": "Pune" },
            { "city_id": "Visakhapatnam", "sortName": "Vk", "city_name": "Visakhapatnam" },
        ],
        "desc": [
            { "Desc1": "Aksharabhyasa is also known as Vidhyarambham is a religious function in which the baby is given initiation for education. By doing this ceremony as per shastras, the child is ready to receive formal school education with the blessings of Lord Ganesha and Matha Saraswati. " },
            { "Perform": 'This ceremony is usually performed on auspicious days like Vasant Panchami, Vijaya Dasami, Saraswati Pooja day, Guru Poornima, Dussehra, Navaratri, Sravana Pournami, Poornima, Ugadi, or Gudi Pudva day, or any other good muhurta dates.', },
            { "Benefits": "Performing Aksharabhyasa Ceremony helps a child to get the blessings from Matha Saraswati and Ganesh Book Purohit for Aksharabhyasa. Purohit will bring all the Pooja Samagri. All the Purohits are well experienced and studied from Vedic Pathshala.", }
        ],
        "reviews": [
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
            { "reviews1": "pooja performed is very good" },
        ],
        "Faq": [
            { "faq1": "Yes, you can pay the partial amount as a token advance to confirm your booking. You can pay the balance amount after the ceremony with cash directly to our Priests or through an Online transfer (Click on the “Pay-Balance” button in your My-Account section corresponding to your order number)." },
            { "faq2": "Ceremonies are typically performed for 1 to 7+ hours depending on the package selected for the ceremony." }
        ]
    }

]







