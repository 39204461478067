import React from 'react'
import { NavLink } from 'react-router-dom'
import "./csscomponents/Footer.css";
import Event from './Event';
import logoP from './image/logp.png';
import razorpayimg from './image/rzp.png';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <div className='footer-link'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-3 footClassStyle'>
                            <NavLink className="navbar-brand novo-footer" to="/">
                                <img src={logoP} alt="logo" />
                            </NavLink>

                            <p className='foo-text'>
                                Maha Ganapathi Havan & Puja is a powerful
                                Vedic ritual of invoking Lord Ganesha,
                                who is also called Vigna Hara (remover of obstacles),
                                this ritual is performed to eradicate all the grant success,
                            </p>
                            <p className='foopay-img'>Payment With Razorpay</p>
                            <img src={razorpayimg} alt="" className='razorpayimg' />

                        </div>
                        <div className='col-lg-3 foo-text'>
                            <h3 className='foo-lnk'>Quick Links</h3>

                            <div className='foo-text'>
                                <ul style={{ listStyle: 'none' }} className="foo-linkUL">
                                    <li className='foo-linkLI'><Link to="/#"><i class="bi bi-cursor-fill"></i>Veda Yatra</Link></li>
                                    <li className='foo-linkLI'><Link to="/#"><i class="bi bi-cursor-fill"></i>Events</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Blogs</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Contact Us</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Temple Services</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">FAQ</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Login / Register</Link></li>
                                </ul>
                            </div>

                        </div>
                        <div className='col-lg-3 foo-text'>
                            <h3 className='foo-lnk'>Puja Services</h3>

                            <p className='foo-text'>
                                <ul style={{ listStyle: 'none' }} className="foo-linkUL">
                                    <li className='foo-linkLI'><Link to="/#">Hindi Pandits in Bangalore</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Tamil Vadhyars in Bangalore</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Telugu Purohits in Bangalore</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Kannada Pujari in Bangalore</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Odiya Purohits in Bangalore</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Hindi Pandits in Chennai</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Tamil Vadhyars in Chennai</Link></li>
                                    <li className='foo-linkLI'><Link to="/#">Kannada Pujari in Chennai</Link></li>

                                </ul>
                            </p>

                        </div>
                        <div className='col-lg-3 foo-text'>
                            <h3 className='foo-lnk'>Contact Us</h3>
                            <div className='foo-text'>
                                <ul className='foomainUL'>
                                    <li className="list-unstyled d-flex"><i class="bi bi-envelope"></i><span className='fo-mail'> nandan@addpronetwork.com</span></li>
                                    <li className="list-unstyled d-flex"><i class="bi bi-telephone"></i><span className='fo-mail'> +91 96860 73073</span></li>
                                    <li className="list-unstyled d-flex"><i class="bi bi-geo-alt"></i><span className='fo-mail'> Addpro Network Private Ltd.
                                        No. 222, 2nd Floor, 10″E” Main, 1st Block,
                                        HRBR Layout, Kalyan Nagar,
                                        Bangalore – 560043.</span></li>
                                </ul>
                            </div>
                        </div>

                        <hr className="my-3" />
                    </div>


                    <div className='row d-flex foo-copyright'>

                        <div className='col-lg-8 footerStyles'>  © 2022 Copyright</div>
                        <div className='col-lg-4 foo-socicon'>
                            <a href='#' className='soc-cl'><i class="bi bi-facebook"></i></a>
                            <a href='#' className='soc-cl'><i class="bi bi-instagram"></i></a>
                            <a href='#' className='soc-cl'><i class="bi bi-linkedin"></i></a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="social">
                <a href="#">
                    <div class="social-btn color-facebook">
                        <div class="icons8-facebook-app"></div>
                        <p class="order-1 google-font margin-telgram">Facebook</p>
                        {/* <i class="bi bi-facebook"></i> */}
                    </div>
                </a>
                
                <a href="#">
                    <div class="social-btn color-instagram">
                        <div class="icons8-instagram"></div>
                        <p class="order-1 google-font margin-instagram">instagram</p>
                        {/* <i class="bi bi-instagram"></i> */}
                    </div>
                </a>
                <a href="#">
                    <div class="social-btn color-whatsapp">
                        <div class="icons8-whatsapp"></div>
                        <p class="order-1 google-font margin-instagram">whats app</p>
                        {/* <i class="bi bi-whatsapp"></i> */}
                    </div>
                </a>
                <a href="#">
                    <div class="social-btn color-telegram">
                        <div class="icons8-telegram-app"></div>
                        <p class="order-1 google-font margin-telgram">Enquiry</p>
                    </div>
                </a>
            </div>

        </>
    )
}

export default Footer