import React from 'react'
import Customerreview from './Customerreview'
import Featuredproduct from './Featuredproduct'
import Herosection from './Herosection'
import InnerHeroSection from './InnerHeroSection'
import Product from './Product'
import Vedayatraworks from './Vedayatraworks'
import Event from './Event'
import Footer from './Footer'

const Home = () => {
  return (
    <div>
      <Herosection />
      <InnerHeroSection />
      <Featuredproduct />
      {/* <Product/> */}
      <Customerreview />
      <Vedayatraworks />
      <Event />
    </div>
  )
}

export default Home